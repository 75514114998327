<template>
	<section class="landTopScreen">
		<div class="landContainer">
			<div class="tagsField">
				<router-link v-for="(item, index) in compdata" :to="{path: item.url}" :key="index" class="tagItem">{{item.name}}</router-link>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: ['compdata']
}
</script>

<style>

</style>