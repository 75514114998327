<template>
	<section class="landBenefit">
		<h2>{{compdata.title}}</h2>
		<div class="twoCol screening">
			<div class="l">
				<div class="scteeningText">
					<p class="caption">{{compdata.subtitle}}</p>
					<ol class="st">
						<li v-for="(item) in compdata.list" :key="item.title">
							{{item.title}}
							<p class="txt">{{item.description}}</p>
							<div class="itemsWrp">
								<template v-if="item.items">
									<div class="item" v-for="(item2, index) in separate(item.items)" :key="index">
										<img :src="require('@/assets/img/ic1.svg')" alt="123" /> {{item2}}
									</div>
								</template>
							</div>
						</li>
					</ol>
				</div>
			</div>
			<div class="r">
				<img :src="path(compdata.image)" :alt="path(compdata.image)" class="sideImg" />
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Remuneration',
	props: ['compdata'],
	methods: {
		separate(str) {
			return str.split(',')
		}
	},
}
</script>

<style lang="scss">
.landBenefit {
	background-color: #f2f2f2;
	padding: 80px 48px 50px;
	position: relative;
	@media (max-width: 1365px) {
		padding: 80px 24px 50px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 0 193px 0 0px;
		background-color: #f8f8f8;
		@media (max-width: 1365px) {
			border-radius: 0 160px 0px 0px;
		}
		@media (max-width: 1023px) {
			border-radius: 0 125px 0px 0px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	h2 {
		max-width: 750px;
		margin: 0 auto;
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		line-height: 110%;
		color: #f4a700;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.twoCol {
		max-width: 750px;
		margin: auto;
		display: flex;
		margin-top: 24px;
		@media (max-width: 1023px) {
			flex-direction: column;
		}
		.l {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
			img {
				width: 100%;
				transform: translate(50px, 0);
				@media (max-width: 1023px) {
					width: calc(100% - 100px);
					transform: none;
					margin: auto;
					display: block;
					margin-top: 24px;
					max-width: 420px;
				}
			}
		}
	}
	.caption {
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 51px;
		color: #000000;
		margin: 16px 0;
	}
	.st {
		li {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 140%;
			color: #000000;
			.itemsWrp {
				display: flex;
				justify-content: space-between;
				margin: 16px 0;
				flex-wrap: wrap;
				.item {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 140%;
					color: #000000;
					display: flex;
					align-items: center;
					margin-bottom: 15px;
					img {
						margin-right: 10px;
					}
				}
			}
		}
	}
	.txt {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: #000000;
	}
}
</style>