<template>
	<section class="landBenefitSecondPart">
		<div class="twoCol compens">
			<div class="l">
				<img :src="path(compdata.image)" :alt="path(compdata.image)" class="sideImg" />
			</div>
			<div class="r">
				<div class="caption">{{compdata.title}}</div>
				<div class="btext" v-html="compdata.subtitle"></div>
				<div class="hint">{{compdata.description}}</div>
				<router-link :to="{path: compdata.menu_link.name_url}"><span class="ic-arrow-short-rigth"></span> {{compdata.menu_link.name_text}}</router-link>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'compensation',
	props: ['compdata'],
}
</script>

<style lang="scss">
.landBenefitSecondPart {
	background-color: #fcd25d;
	position: relative;
	padding: 1px 48px 0px;
	@media (max-width: 1365px) {
		padding: 0px 24px 0px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 0 0 0 193px;
		background-color: #f8f8f8;
		@media (max-width: 1365px) {
			border-radius: 0 0 0 160px;
		}
		@media (max-width: 1023px) {
			border-radius: 0 0 0 125px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	.twoCol {
		max-width: 750px;
		margin: auto;
		display: flex;
		margin-top: 24px;
		@media (max-width: 1023px) {
			flex-direction: column-reverse;
		}
		.l {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
			.sideImg {
				width: 100%;
				transform: translate(-50px, 0);
				@media (max-width: 1023px) {
					transform: none;
					margin-top: 24px;
					max-width: 420px;
					margin: auto;
					display: block;
				}
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
	}
	.caption {
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 51px;
		color: #000000;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.compens {
		margin-top: 72px;
		a {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
			display: flex;
			align-items: center;
			text-decoration: none;
			margin-top: 34px;
			.ic-arrow-short-rigth {
				font-size: 17px;
				margin-right: 8px;
			}
		}
	}
	.btext {
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		line-height: 140%;
		color: #000000;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.hint {
		margin-top: 16px;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: #000000;
		padding-left: 28px;
		position: relative;
		&:before {
			content: "";
			width: 3px;
			height: 3px;
			border-radius: 50%;
			top: 10px;
			left: 10px;
			position: absolute;
			background-color: #000;
		}
	}
}
</style>