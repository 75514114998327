<template>
<section class="landNextBanner">
	<div class="twoСol">
		<div class="l">
			<img :src="path(compdata.image)" :alt="path(compdata.image)" />
		</div>
		<div class="r">
			<div class="wrp">
				<div class="caption">{{compdata.title}}</div>
				<div class="caption2">{{compdata.subtitle}}</div>
				<div class="txt">{{compdata.title2}}</div>
				<p class="txt" v-if="compdata.menu_link.name_url">
					<router-link :to="{path: compdata.menu_link.name_url}"
						><span class="ic-arrow-short-rigth"></span> {{compdata.menu_link.name_text}}</router-link
					>
				</p>
				<p class="txt" v-if="compdata.menu_link2.name_url">
					<router-link :to="{path: compdata.menu_link2.name_url}"
						><span class="ic-arrow-short-rigth"></span> {{compdata.menu_link2.name_text}}</router-link
					>
				</p>
			</div>
		</div>
	</div>
</section>
</template>

<script>
export default {
	name: 'whats-next',
	props: ['compdata']
}
</script>

<style lang="scss">
.landNextBanner {
	background-color: #b6d9f3;
	padding: 80px 48px 0px;
	@media (max-width: 1365px) {
		padding: 80px 24px 0px;
	}
	@media (max-width: 1023px) {
		padding: 64px 24px;
	}
	@media (max-width: 575px) {
		padding: 64px 16px;
	}
	.twoСol {
		display: flex;
		@media (max-width: 1023px) {
			flex-direction: column;
		}
		.l {
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				max-width: calc(100% - 20px);
			}
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
	}
	.wrp {
		max-width: 350px;
		@media (max-width: 1023px) {
			margin: auto;
			margin-top: 32px;
			max-width: unset;
		}
	}
	.caption {
		font-style: normal;
		font-weight: 600;
		font-size: 49px;
		line-height: 110%;
		color: #e10b17;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.caption2 {
		font-style: normal;
		font-weight: 600;
		font-size: 29px;
		line-height: 140%;
		color: #000000;
		margin-top: 16px;
		@media (max-width: 1023px) {
			font-size: 35px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 20px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 16px;
			line-height: 110%;
		}
	}
	.txt {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
		margin: 16px 0;
		a {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
			display: flex;
			align-items: center;
			text-decoration: none;
			margin-top: 34px;
			.ic-arrow-short-rigth {
				font-size: 17px;
				margin-right: 8px;
			}
		}
	}
	
}
</style>