<template>
	<div class="viewWrapper">
		<MobHeaderMenu @menuswitch="$emit('menuswitch')" style="position: static"></MobHeaderMenu>

		<transition name="fadefast" mode="out-in">
			<div v-if="!loaded" class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
			<div v-if="loaded" :key="'pagecontent'" class="ovh">
				<template v-for="(comp, index) in constructor">
					<component
						v-if="comp.visibility == 1"
						:key="index"
						:is="getname(comp.component)"
						:compdata="comp.content"
						@menuswitch="$emit('menuswitch')"
					></component>
				</template>
			</div>
		</transition>

	</div>
</template>

<script>
import LottieVue from "vue-lottie-light"
import MobHeaderMenu from "@/components/common/MobHeaderMenu.vue";
import AboutFirstScreen from "@/components/landings/AboutFirstScreen.vue";
import TagsField from "@/components/landings/TagsFields.vue";
import BeShure from "@/components/landings/BeShure.vue";
import InfoDoscSection from "@/components/landings/InfoDoscSection.vue";
import Remuneration from "@/components/landings/Remuneration.vue";
import Compensation from "@/components/landings/Compensation.vue";
import Steps2 from "@/components/landings/Steps2.vue";
import RecoverAfterDonation from "@/components/landings/RecoverAfterDonation.vue";
import WhatsNext from "@/components/landings/WhatsNext.vue";
import SignNextDonation from "@/components/landings/SignNextDonation.vue";

// import animationData1 from '@/assets/lottie/lottie1.json'
export default {
	components: {
		LottieVue,
		MobHeaderMenu,
		"droplets": AboutFirstScreen,
		"menucomp": TagsField,
		"be-sure": BeShure,
		"documents": InfoDoscSection,
		"remuneration": Remuneration,
		"compensation": Compensation,
		"steps2": Steps2,
		"recover-after-donation": RecoverAfterDonation,
		"whats-next": WhatsNext,
		"sign-next-donation": SignNextDonation,
	},
	data() {
		return {
			// defaultOptions: {animationData: animationData1},

			loaded: false,
			constructor: null,
		}
	},
	methods: {
		getname(n) {
			switch (n) {
				case 'menu':
					return 'menucomp'
			}
			return n;
		}
	},
	created() {
		this.axios.post("/api/landing/get-by-slug", { lang: this.$i18n.locale, slug: 'stati-donorom-suuuper-prosto', }).then((response) => {
			this.constructor = response.data.data.constructor;
			document.title = response.data.data.translate.title;
			this.loaded = true;
		});
	},
};
</script>

<style lang="scss">
.viewWrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
// .ovh{
// 	overflow-x: hidden;
// }
.landTopScreen {
	flex-grow: 1;
	padding: 24px 48px 0;
	background-color: #f8f8f8;
	@media (max-width: 1365px) {
		padding: 24px 24px 0;
	}
	@media (max-width: 575px) {
		padding: 24px 16px 0;
	}
	.landContainer {
		max-width: 1012px;
		margin: auto;
	}
	.topIllustration {
		display: block;
		margin: auto;
		max-width: 100%;
		@media (max-width: 575px) {
			margin-top: 32px;
		}
	}
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 75px;
		line-height: 110%;
		text-align: center;
		color: #ff6058;
		margin: 24px auto 0;
		max-width: 750px;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 30px;
			line-height: 110%;
		}
	}
	.subTitle {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 160%;
		text-align: center;
		color: #000000;
		@media (max-width: 1199px) {
			margin-top: 16px;
		}
	}
	.g {
		margin-top: 16px;
		.t1 {
			font-style: normal;
			font-weight: 600;
			font-size: 34px;
			line-height: 43px;
			color: #000000;
			@media (max-width: 575px) {
				font-size: 24px;
				margin-top: 48px;
			}
			.ic-curved-arrow {
				margin-right: 10px;
				font-size: 46px;
				vertical-align: middle;
			}
		}
		a {
			width: 324px;
			height: 64px;
			border-radius: 1px;
			text-align: center;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 150.3%;
			color: #ffffff;
			background-color: #e10b17;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			margin-top: 20px;
			transition: 0.8s cubic-bezier(0.76, -0.51, 0.29, 1.6);
			border: none;
			@media (max-width: 575px) {
				width: 100%;
			}
			&:hover {
				background-color: #c4141d;
			}
			.ic-hand-1 {
				font-size: 32px;
				margin-right: 33px;
			}
		}
	}
	.tagsField {
		padding-bottom: 60px;	
		@media (max-width: 1365px) {
			margin: 0 !important;
		}
	}
	// & + .landDonorRequiments{
	// 	margin-top: 60px;
	// }
}

</style>