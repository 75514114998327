<template>
<section class="landDonationRecovery">
	<div class="twoCol">
		<div class="l">
			<div class="caption">{{compdata.title}}</div>
			<div class="txt">{{compdata.subtitle}}</div>
			<div class="caption2">{{compdata.title2}}</div>
			<div class="txt">{{compdata.subtitle2}}</div>
			<p class="txt">
				<router-link :to="{path: compdata.menu_link.name_url}"><span class="ic-arrow-short-rigth"></span> {{compdata.menu_link.name_text}}</router-link>
			</p>
		</div>
		<div class="r">
			<img :src="path(compdata.image)" :alt="path(compdata.image)" />
		</div>
	</div>
</section>
</template>

<script>
export default {
	name: 'recover-after-donation',
	props: ['compdata']
}
</script>

<style lang="scss">
.landDonationRecovery {
	padding: 80px 48px 80px;
	@media (max-width: 1365px) {
		padding: 80px 24px 80px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	.twoCol {
		display: flex;
		max-width: 750px;
		margin: auto;
		@media (max-width: 1023px) {
			flex-direction: column;
		}
		.l {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
			img {
				width: calc(100% - 20px);
				margin-left: auto;
				display: block;
				@media (max-width: 1023px) {
					width: 100%;
					margin: auto;
					max-width: 420px;
				}
			}
		}
	}
	.caption {
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 51px;
		color: #000000;
		margin: 0;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.caption2 {
		font-style: normal;
		font-weight: 600;
		font-size: 29px;
		line-height: 37px;
		color: #000000;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.txt {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: #000000;
		margin: 16px 0;
		a {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
			display: flex;
			align-items: center;
			text-decoration: none;
			margin-top: 34px;
			.ic-arrow-short-rigth {
				font-size: 17px;
				margin-right: 8px;
			}
		}
	}
}
</style>