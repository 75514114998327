<template>
	<section class="landDonorRequiments">
		<h2 >{{compdata.title}}</h2>
		<div class="itemsWrp">
			<div class="item">
				<div class="round">
					<span class="s1">{{compdata.item1_s1}}</span>
					<span class="s2">{{compdata.item1_s2}}</span>
				</div>
				<div class="caption">{{compdata.item1_caption}}</div>
			</div>
			<div class="item">
				<div class="round">
					<span class="s1">{{compdata.item2_s1}}</span>
					<span class="s2">{{compdata.item2_s2}}</span>
				</div>
				<div class="caption">{{compdata.item2_caption}}</div>
			</div>
			<div class="item">
				<div class="round">
					<img :src="path(compdata.item3_image)" alt="" class="icon" />
				</div>
				<div class="caption">{{compdata.item3_caption}}</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "BeSure",
	props: ['compdata'],

}
</script>

<style lang="scss">
.landDonorRequiments {
	padding: 65px 48px 0;
	background-color: #f8f8f8;
	@media (max-width: 1365px) {
		padding: 65px 24px 0;
	}
	@media (max-width: 1023px) {
		padding: 32px 16px;
	}
	position: relative;
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 193px 0px 0 0px;
		background-color: #f2f2f2;
		@media (max-width: 1365px) {
			border-radius: 160px 0px 0 0px;
		}
		@media (max-width: 1023px) {
			border-radius: 0px 0px 0 0px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	h2 {
		max-width: 750px;
		margin: 0 auto;
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 110%;
		color: #aeaeb2;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.itemsWrp {
		max-width: 750px;
		margin: auto;
		margin-top: 32px;
		display: flex;
		justify-content: space-around;
		@media (max-width: 575px) {
			flex-direction: column;
		}
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			@media (max-width: 1023px) {
				margin: 24px 0;
			}
			.round {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border-top-left-radius: 0;
				width: 97px;
				height: 97px;
				background-color: red;
				font-style: normal;
				font-weight: 600;
				line-height: 120%;
				letter-spacing: -0.01em;
				color: #ffffff;
				margin-bottom: 15px;
				.s1 {
					font-size: 40px;
				}
				.s2 {
					font-size: 20px;
					margin-top: 12px;
				}
			}
			.caption {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				color: #000000;
				max-width: 155px;
			}
			&:nth-child(1) {
				.round {
					background-color: #f4a700;
				}
			}
			&:nth-child(2) {
				.round {
					background-color: #5dc2c2;
				}
			}
			&:nth-child(3) {
				.round {
					background-color: #6cb6f1;
				}
			}
		}
	}
}
</style>