<template>
	<section class="landTopScreen">
		<div class="topHd">
			<router-link :to="{name: 'home', params: {locale: $i18n.locale == 'uk' ? null : $i18n.locale}}" class="backBtn">
				<span class="ic-arrow-long-left"></span> {{$t('menu.mainPage')}}
			</router-link>
			<a v-if="$store.getters.phone" :href="`tel:${$store.getters.phone.number}`" class="ph">{{$store.getters.phone.number}}</a>
		</div>
		<div class="landContainer">
			<img :src="path(compdata.image)" :alt="path(compdata.image)" class="topIllustration" />
			<h1 :style="{color: compdata.title_color || '#FF6058'}">{{compdata.title}}</h1>
			<div class="subTitle">{{compdata.subtitle}}</div>
			<div class="g">
				<div class="t1">
					<span class="ic-curved-arrow"></span>{{compdata.text}}
				</div>
				<a :href="$store.getters.formLnk" target="_blank"><span class="ic-hand-1"></span>{{compdata.btn_text}}</a>
			</div>
		</div>
	</section>
</template>

<script>

export default {
	props: ['compdata'],
	components: {
		
	},
}
</script>

<style lang='scss'>
.landTopScreen {
	flex-grow: 1;
	padding: 24px 48px 0;
	background-color: #f8f8f8;
	@media (max-width: 1365px) {
		padding: 24px 24px 0;
	}
	@media (max-width: 575px) {
		padding: 24px 16px 0;
	}
	.landContainer {
		max-width: 1012px;
		margin: auto;
	}
	.topIllustration {
		display: block;
		margin: auto;
		max-width: 100%;
		@media (max-width: 575px) {
			margin-top: 32px;
		}
	}
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 75px;
		line-height: 110%;
		text-align: center;
		color: #ff6058;
		margin: 24px auto 0;
		max-width: 750px;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.subTitle {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 160%;
		text-align: center;
		color: #000000;
		@media (max-width: 1199px) {
			margin-top: 16px;
		}
	}
	.g {
		margin-top: 16px;
		.t1 {
			font-style: normal;
			font-weight: 600;
			font-size: 34px;
			line-height: 43px;
			color: #000000;
			@media (max-width: 575px) {
				font-size: 24px;
				margin-top: 48px;
			}
			.ic-curved-arrow {
				margin-right: 10px;
				font-size: 46px;
				vertical-align: middle;
			}
		}
		a {
			width: 324px;
			height: 64px;
			border-radius: 1px;
			text-align: center;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 150.3%;
			color: #ffffff;
			background-color: #e10b17;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			margin-top: 20px;
			transition: 0.8s cubic-bezier(0.76, -0.51, 0.29, 1.6);
			border: none;
			@media (max-width: 575px) {
				width: 100%;
			}
			&:hover {
				background-color: #c4141d;
			}
			.ic-hand-1 {
				font-size: 32px;
				margin-right: 33px;
			}
		}
	}
	.tagsField {
		@media (max-width: 1365px) {
			margin: 0 !important;
		}
	}
	.landTextBlock {
		padding: 24px 0;
		.caption {
			font-style: normal;
			font-weight: 600;
			font-size: 40px;
			line-height: 110%;
			text-align: center;
			color: #aeaeb2;
			margin: 0;
			@media (max-width: 575px) {
				font-size: 30px;
			}
		}
		p {
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 160%;
			color: #000000;
		}
	}
	.landImgSliderWrp {
		.landImgSlider {
			.slide {
				height: 448px;
				padding-right: 10px;
				@media (max-width: 575px) {
					height: 225px;
					max-width: calc(100vw - 50px);
				}
				img {
					height: 100%;
					display: block;
					@media (max-width: 575px) {
						max-width: calc(100vw - 50px);
					}
				}
			}
		}
		.nav {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 32px 0 24px;
			img {
				margin: 0 10px;
			}
			.ic-arrow-long-left,
			.ic-arrow-long-rigth {
				font-size: 15px;
				cursor: pointer;
			}
		}
	}
}
</style>