<template>
	<section class="landSignSection">
		<div class="twoCol">
			<div class="l">
				<div class="caption">{{compdata.title}}</div>
				<div class="txt">{{compdata.subtitle}}</div>
				<div class="g">
					<div class="t1">{{compdata.title2}}</div>
					<a :href="$store.getters.formLnk" target="_blank"><span class="ic-hand-1"></span>{{compdata.btn_name}}</a>
				</div>
			</div>
			<div class="r">
				<img :src="path(compdata.image)" :alt="path(compdata.image)" />
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'sign-next-donation',
	props: ['compdata'],
}
</script>

<style lang="scss">
.landSignSection {
	background-color: #b6d9f3;
	padding: 135px 48px 160px;
	position: relative;
	@media (max-width: 1365px) {
		padding: 135px 24px 160px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 0 193px 0 0px;
		background-color: #f2f2f2;
		@media (max-width: 1365px) {
			border-radius: 0 160px 0px 0px;
		}
		@media (max-width: 575px) {
			border-radius: 0 125px 0px 0px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	.twoCol {
		max-width: 750px;
		margin: auto;
		display: flex;
		@media (max-width: 1023px) {
			flex-direction: column-reverse;
		}
		.l {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
			img {
				margin-top: 70px;
				width: calc(100% - 70px);
				transform: translate(50px, 0);
				@media (max-width: 1023px) {
					display: block;
					max-width: 420px;
					margin: auto;
					margin-bottom: 32px;
					transform: none;
				}
			}
		}
	}
	.caption {
		font-style: normal;
		font-weight: 600;
		font-size: 51px;
		line-height: 110%;
		color: #f4a700;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.txt {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: #000000;
		margin: 16px 0;
		a {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
			display: flex;
			align-items: center;
			text-decoration: none;
			margin-top: 34px;
			.ic-arrow-short-rigth {
				font-size: 17px;
				margin-right: 8px;
			}
		}
	}
	.g {
		margin-top: 48px;
		.t1 {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 26px;
			color: #000000;
		}
		a {
			width: 324px;
			height: 64px;
			border-radius: 1px;
			text-align: center;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 150.3%;
			color: #ffffff;
			background-color: #e10b17;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			margin-top: 20px;
			transition: 0.8s cubic-bezier(0.76, -0.51, 0.29, 1.6);
			border: none;
			@media (max-width: 575px) {
				width: 100%;
			}
			&:hover {
				background-color: #c4141d;
			}
			.ic-hand-1 {
				font-size: 32px;
				margin-right: 33px;
			}
		}
	}
}
</style>