<template>
	<section class="landDocInfo">
		<h2>{{compdata.title}}</h2>
		<div class="twoCol">
			<div class="l">
				<p class="captionItem" v-for="(item, index) in compdata.list" :key="index">{{`${index}. ${item.item}`}}</p>
				<div class="itemhint">{{compdata.description}}</div>
			</div>
			<div class="r">
				<img :src="path(compdata.image)" :alt="path(compdata.image)" />
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Documents',
	props: ['compdata'],
}
</script>

<style lang="scss">
.landDocInfo {
	padding: 65px 48px 80px;
	background-color: #f2f2f2;
	@media (max-width: 1365px) {
		padding: 65px 24px 80px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	h2 {
		max-width: 750px;
		margin: auto;
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 110%;
		color: #aeaeb2;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.twoCol {
		max-width: 750px;
		margin: auto;
		display: flex;
		margin-top: 24px;
		@media (max-width: 1023px) {
			flex-direction: column;
		}
		.l {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
		.r {
			width: 50%;
			@media (max-width: 1023px) {
				width: 100%;
			}
			img {
				width: 100%;
				transform: translate(50px, 0);
				@media (max-width: 1023px) {
					width: calc(100% - 100px);
					transform: none;
					margin: auto;
					display: block;
					margin-top: 24px;
					max-width: 420px;
				}
			}
		}
	}
	.captionItem {
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 43px;
		color: #000000;
		margin: 16px 0;
		@media (max-width: 1023px) {
			font-size: 30px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 26px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 24px;
			line-height: 110%;
		}
	}
	.itemhint {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: #000000;
		padding-left: 28px;
		position: relative;
		&:before {
			content: "";
			width: 3px;
			height: 3px;
			border-radius: 50%;
			top: 10px;
			left: 10px;
			position: absolute;
			background-color: #000;
		}
	}
}
</style>