<template>
	<section class="landSteps">
		<h2>{{ compdata.title }}</h2>
		<div class="stepsWrap">
			<div class="scroller">
				<div class="item" v-for="(item, index) in compdata.list" :key="index">
					<div class="imgWrp">
						<!-- <lottie-vue :options="defaultOptions"> </lottie-vue> -->
						<img :src="path(item.image)" :alt="path(item.image)" />
					</div>
					<p class="num">{{ index }}.</p>
					<p class="caption">{{ item.title }}</p>
					<p class="txt" v-html="item.text"></p>
					<!-- <router-link>{{compdata.title}}</router-link> -->
					<template v-if="item.url">
						<router-link
							v-if="!isExtUrl(item.url)"
							:to="{ path: item.url }"
							class="more"
							><span class="ic-arrow-short-rigth"></span>{{compdata.btn_name}}</router-link
						>
						<a
							v-if="isExtUrl(item.url)"
							:href="item.url"
							target="_blank"
							class="more"
							><span class="ic-arrow-short-rigth"></span>{{compdata.btn_name}}</a
						>
					</template>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "DonorsStepsTwo",
	props: ["compdata"],
};
</script>

<style lang="scss">
.landSteps {
	background-color: #f8f8f8;
	padding: 80px 48px 80px;
	position: relative;
	@media (max-width: 1365px) {
		padding: 80px 24px 80px;
	}
	@media (max-width: 1023px) {
		padding: 64px 16px;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 0 193px 0 193px;
		background-color: #fcd25d;
		@media (max-width: 1365px) {
			border-radius: 0 160px 0px 160px;
		}
		@media (max-width: 1023px) {
			border-radius: 0 125px 0px 125px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	h2 {
		margin: auto;
		max-width: 1000px;
		font-style: normal;
		font-weight: 600;
		font-size: 50px;
		line-height: 110%;
		color: #000000;
		@media (max-width: 1023px) {
			font-size: 60px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 40px;
			line-height: 110%;
		}
		@media (max-width: 374px) {
			font-size: 26px;
			line-height: 110%;
		}
	}
	.stepsWrap {
		margin: auto;
		max-width: 1000px;
		padding: 40px 0px 0;
		@media (max-width: 1599px) {
			padding: 40px 0px 0;
		}
		@media (max-width: 1023px) {
			padding: 40px 0px 0;
		}
		overflow-x: auto;
		overflow-y: hidden;
		.scroller {
			display: flex;
			justify-content: space-between;
			@media (max-width: 1023px) {
				display: block;
				overflow-x: auto;
				white-space: nowrap;
				padding: 0 48px 30px;
			}
		}
		.item {
			width: calc(25% - 30px);
			// margin: 0 20px;
			vertical-align: top;
			@media (max-width: 1023px) {
				display: inline-block;
				flex: 0 0 250px;
				width: 250px;
			}
			& + .item {
				@media (max-width: 1023px) {
					margin-left: 24px;
				}
			}
			.imgWrp {
				img {
					display: block;
					width: 100%;
				}
			}
			.num {
				font-weight: 800;
				font-size: 48px;
				line-height: 100%;
				color: #fff;
				margin: 10px 0 0;
			}
			.caption {
				font-weight: 600;
				font-size: 24px;
				line-height: 31px;
				color: #000000;
				margin: 20px 0 0;
				white-space: normal;
			}
			.txt {
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #000000;
				margin: 20px 0 0;
				white-space: normal;
				a {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #000000;
					display: flex;
					align-items: center;
					text-decoration: none;
					margin-top: 34px;
					.ic-arrow-short-rigth {
						font-size: 17px;
						margin-right: 8px;
					}
				}
			}
		}
		.item:nth-child(odd) {
			padding-top: 110px;
			@media (max-width: 1023px) {
				padding-top: 0;
			}
		}
	}
	.more {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #000000;
		text-decoration: none;
		// margin-top: 24px;
		display: block;
		.ic-arrow-short-rigth {
			margin-right: 8px;
		}
	}
}
</style>